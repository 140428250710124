.footer {
    width: 100%;
    color: white;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .contact-info {
    flex: 1;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  .contact-info a {
    color: white;
    text-decoration: none;
  }
  
  .social-media {
    display: flex;
  }
  
  .social-icon {
    margin-right: 10px;
    color: white;
    font-size: 20px;
  }
  
  .social-icon:last-child {
    margin-right: 0;
  }
  
  .social-icon:hover {
    color: #ff9800; 
  }
  
  .social-icon i {
    transition: color 0.3s; 
  }
  
  .social-icon:hover i {
    transform: scale(1.1); 
  }
  
  
  .copyright {
    text-align: center;
    margin-top: 20px;
  }
  
  
  .favicon {
    margin-top: 10px;
  }
  