.Dex {
  margin: 15px;
  border: 2px solid #0b1f81;
  background: linear-gradient(135deg, #091335, #031274);
  border-radius: 10px;
  box-shadow: 0 0 3px #0b1f81;
  position: relative; /* Make sure the pseudo-element is positioned relative to the .Dex element */
}



#dexscreener-embed {
    position: relative;
    width: 100%;
    padding-bottom: 125%;
  }
  
  @media (min-width: 1400px) {
    #dexscreener-embed {
        padding-bottom: 65%;
    }
  }
  
  #dexscreener-embed iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
  }
  

  