.images {
    width: 400px;
    transition: transform 0.3s ease;
}

.chain-image {
    width: 400px;
    text-align: right;
    animation: rocketAnimation 2s forwards; 
    animation-delay: 3s;
    margin: -40px;
  }

  
  .chain-image img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }

  .images:hover {
    transform: scale(1.08) rotate(-4deg);  
  }
  
  .all-cards-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .card-with-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  

  .card-with-text.reverse {
    flex-direction: row;
  }

  @media only screen and (max-width: 767px) {
    .images{
      display: none;
    }

    .card-with-text.reverse {
      flex-direction: column-reverse;
    }
  }