.navbar {
    width: 200px; 
    padding: 10px;
    border: 2px solid #0b1f81;
    background: linear-gradient(135deg, #090f24, #031274);
    border-radius: 10px;
    box-shadow: 0 0 3px #0b1f81;
    display: flex;
    flex-direction: column;
    height: 635px;
    z-index: 2;
  }
  
  .navbar-title {
    color: white;
    padding: 10px 0;
    text-align: center;
  }
  
  .navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .navbar-nav li {
    width: 100%;
    margin: 5px 0;
  }
  
  .navbar-nav button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: transparent;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s, text-shadow 0.3s; /* Updated transitions */
    cursor: pointer;
    transform: scale(1); /* Normal state scale */
  }
  
  .navbar-nav button:hover, .navbar-nav button.active {
    background-color: #031274;
    color: #ffffff;
    transform: scale(1.06); /* Slightly scale up on hover and active */
  }
  
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .navbar {
      width: 100%;
      height: auto;
      border-radius: 0;
      flex-direction: row;
      overflow-x: auto;
    }
  
    .navbar-nav {
      flex-direction: row;
    }
  
    .navbar-nav li {
      margin: 0;
      flex-grow: 1;
      display: flex;
    }
  
    .navbar-nav button {
      flex-grow: 1;
      text-align: center;
    }
  }
  