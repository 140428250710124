.page {
  color: white;
  width: 100%;
  height: 100%;
}

@keyframes slideInBanner {
  0% {
    transform: translateY(40vh);
    opacity: 0;
  }

  50% {
    transform: translateY(35vh);
    opacity: 1;
  }

  100% {
    transform: translateY(0vh);
  }
}

.banner-text {
  font-size: 5rem;
  animation: slideInBanner 3s ease;
}

.banner p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

@keyframes slideInContent {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.content {
  animation: slideInContent 5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-conteiner {
  display: flex;
  justify-content: center;
  --color-text: #ffffff;
  --color-background: #0025db;
  --color-outline: #111943;
  --color-shadow: #00000080;
  margin-top: 30px;
  margin-bottom: 10px;
  cursor: pointer;
}

.btn-content {
  display: flex;
  align-items: center;
  padding: 5px 30px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: var(--color-text);
  background: var(--color-background);
  transition: 1s;
  border-radius: 100px;
  box-shadow: 0 0 0.2em 0 var(--color-background);
}

.btn-content:hover,
.btn-content:focus {
  transition: 0.5s;
  -webkit-animation: btn-content 1s;
  animation: btn-content 1s;
  outline: 0.1em solid transparent;
  outline-offset: 0.2em;
  box-shadow: 0 0 0.4em 0 var(--color-background);
}

.btn-content .icon-arrow {
  transition: 0.5s;
  margin-right: 0px;
  transform: scale(0.6);
}

.btn-content:hover .icon-arrow {
  transition: 0.5s;
  margin-right: 25px;
}

.icon-arrow {
  width: 20px;
  margin-left: 15px;
  position: relative;
  top: 6%;
}


#arrow-icon-one {
  transition: 0.4s;
  transform: translateX(-60%);
}

#arrow-icon-two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.btn-content:hover #arrow-icon-three {
  animation: color_anim 1s infinite 0.2s;
}

.btn-content:hover #arrow-icon-one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.btn-content:hover #arrow-icon-two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}


@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: var(--color-background);
  }

  100% {
    fill: white;
  }
}


@-webkit-keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

@keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

.card {
  width: 240px;
  height: 300px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}

.card-inner {
  width: inherit;
  height: inherit;
  background: rgba(255, 255, 255, 0.13);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  align-content: end;
}

.images-Dennis {
  max-width: 240px;
  border-radius: 8px;
  margin-bottom: -4px;
}

.images-Daniel {
  max-width: 270px;
  margin-bottom: -4px;
  margin-left: -30px;
  border-radius: 8px;
}

.images-Barry {
  max-width: 290px;
  border-radius: 8px;
  margin-bottom: -4px;
  margin-left: -50px;

}

.images-Alan {
  max-width: 240px;
  border-radius: 8px;
  margin-bottom: -4px;

}

.images-Debora {
  max-width: 310px;
  border-radius: 8px;
  margin-bottom: -4px;
  margin-left: -35px;

}

.card:hover {
  transform: scale(1.04) rotate(1deg);
}

.circle {
  width: 100px;
  height: 100px;
  background: radial-gradient(#0022ff, #002dce);
  border-radius: 30%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle:nth-child(1) {
  top: -25px;
  left: -25px;
}

.circle:nth-child(2) {
  bottom: -25px;
  right: -25px;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}

.all-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 70px;
}

.title {
  font-size: 4rem;
}

.title-contact {
  font-size: 4rem;
  margin-bottom: 0px;
}

.subtitle-contact {
  font-size: 3rem;
  margin-bottom: 10px;
  margin-top: 0px;
}

.project-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.description {
  width: 50%;
  padding: 20px;
  border: 2px solid #0b1f81;
  background: linear-gradient(135deg, #090f24, #031274);
  
  border-radius: 10px;
  box-shadow: 0 0 3px #0b1f81;
  transition: transform 0.3s ease;
  
}

.description:hover {
  transform: scale(1.01) rotate(-1deg);
}

@keyframes rocketAnimation {
  0% {
    transform: translateX(400px) rotate(-80deg);
    
  }

  100% {
    transform: translateX(0) rotate(0deg);
    
  }
}

.rocket-image {
  width: 400px;
  text-align: right;
  animation: rocketAnimation 2s forwards;
  
  animation-delay: 3s;
  margin: -40px;
}


.rocket-image img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  animation: moveLogo 5s infinite alternate;

}

.rocket-image img:hover {
  transform: scale(1.15) rotate(-10deg);
}


.description .title {
  font-size: 34px;
  
}

.description .content {
  font-size: 20px;
  
}

.text-chain {
  display: flex;
  align-items: center;
  justify-content: center;
}


.chain-fi-logo {
  margin-top: -150px;
  margin-bottom: -160px;
  margin-left: -80px;
  margin-right: -80px;
  width: 500px;
}

@keyframes moveLogo {
  from {
    transform: translateY(-15px) rotate(-10deg);
  }

  to {
    transform: translateY(15px) rotate(10deg);
  }
}


.title {
  font-size: 5rem;
  animation: slideInBanner 3s ease;
}

.title-contact {
  font-size: 5rem;
  animation: slideInBanner 3s ease;
}


.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 90vw;
}

.the-card {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(90deg, #000c4f, #040b35 50%, #000c4f);
  border: 2px solid #0b1f81;
  border-radius: 20px;
  box-shadow: 0 0 3px #0b1f81;
  transition: transform 0.3s ease;
  max-height: 700px;
  overflow-y: auto;

}

.the-card:hover {
  transform: scale(1.02);
}

.the-card h2 {
  font-size: 28px;
  
  color: #ffffff;
  margin-bottom: 0px;
  
  margin-top: 0px;
  text-align: center;
  
}

.the-card p {
  font-size: 18px;
  
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
  
}

.the-card img {
  width: 120px;
  
  margin-bottom: 15px;
  
}

.card-text {
  font-size: 16px;
  
  line-height: 1.6;
  
  text-align: center;
}

.buttons-together {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: -30px;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {

  .btn-content {

    padding: 5px 30px;
    font-weight: 600;
    font-size: 20px;

  }

  .title {
    font-size: 2rem;
    animation: slideInBanner 3s ease;
  }

  .title-contact {
    font-size: 3rem;
    animation: slideInBanner 3s ease;
  }

  .subtitle-contact {
    font-size: 2rem;

  }

  .banner p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .project-container {
    flex-direction: column-reverse;
  }

  .description {
    width: 80%;
  }

  .description h2 {
    font-size: 22px;
  }

  .description .title {
    font-size: 24px;
    
  }

  .description .content {
    font-size: 14px;
    
  }

  .rocket-image {
    display: none;
  }

  .chain-fi-logo {
    width: 300px;
    margin: 0px;
    margin-top: -40px;
    margin-bottom: -80px;
  }


  .rocket-image {
    width: 130px;
    margin-bottom: 20px;

  }

  .buttons-together {
    flex-direction: column;
    gap: 0px;
    margin-top: 0px;
    margin-bottom: 0px;

  }

  .description:hover {
    transform: none;
  }

  .the-card h2 {
    font-size: 18px;
    
    color: #ffffff;
    margin-top: 0px;
    text-align: center;
    
  }

  .the-card p {
    font-size: 14px;
    
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    
  }

  .the-card img {
    width: 80px;
    
    margin-bottom: 15px;
    
  }
}

.project-container.reverse {
  display: flex;
  flex-direction: row-reverse;
}

ul {
  list-style: none;
  padding: 0;
  
  margin: 0;
  
}


.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}

.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 20%;
  color: #4d4d4d;
  background-color: #ffff;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 50%);
}

.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}

.example-2 .icon-content a:hover {
  color: white;
}

.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="Discord"] .filled,
.example-2 .icon-content a[data-social="Discord"]~.tooltip {
  background-color: #4954d1;
}

.example-2 .icon-content a[data-social="Youtube"] .filled,
.example-2 .icon-content a[data-social="Youtube"]~.tooltip {
  background-color: #bd081c;
}

.example-2 .icon-content a[data-social="Reddit"] .filled,
.example-2 .icon-content a[data-social="Reddit"]~.tooltip {
  background-color: #FF4500;
}

.example-2 .icon-content a[data-social="X"] .filled,
.example-2 .icon-content a[data-social="X"]~.tooltip {
  background-color: #090909;
}

.example-2 .icon-content a[data-social="QuestN"] .filled,
.example-2 .icon-content a[data-social="QuestN"]~.tooltip {
  background-color: #6fbe32;
}

.example-2 .icon-content a[data-social="Chest"] .filled,
.example-2 .icon-content a[data-social="Chest"]~.tooltip {
  background-color: #e30531;
}

.pdf-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.699);
  z-index: 9999;
  padding: 20px;
  padding-top: 30px;
  box-sizing: border-box;
}

.pdf-viewer .rpv-core__viewer {
  width: 90%;
  height: 90%;
  max-width: 800px;
  max-height: 90vh;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  overflow: auto;
}


.pdf-viewer .rpv-core__viewer * {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0);
}

.close-button {
  position: absolute;
  top: 5px;
  
  left: 50%;
  transform: translateX(-50%);
  background: #0400ff;
  color: #fff;
  border: none;
  padding: 7px 13px;
  
  border-radius: 5px;
  cursor: pointer;
  z-index: 10000;
  font-size: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  background: #a10000;
  transform: translateX(-50%) scale(1.05);
}

.bottom20px {
  margin-bottom: 100px;
}