.Ecosystem-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .right-and-left {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .trade-box {
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid #0b1f81;
    background: linear-gradient(135deg, #091335, #031274);
    border-radius: 10px;
    box-shadow: 0 0 3px #0b1f81;
    height: 350px;
    width: 280px;
  }
  
  .tab-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .tab-button {
    background-color: #0b1f81;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1;
    margin: 0 5px;
  }
  
  .tab-button.active {
    background-color: #1320934a;
    color: #ffffff;
  }

  .submit-button {
    background-color: #0b1f81;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #10187a;
  }
  
  .earning-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .earning-table th, .earning-table td {
    padding: 15px;
    text-align: left;
    border: 2px solid #132093;
  }
  
  .earning-table th {
    background-color: #051caf4d;
    color: #c5c6c7;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .earning-table tbody tr:nth-child(even) {
    background-color: #051caf4d;
  }
  
  .earning-table tbody tr:nth-child(odd) {
    background-color: #0415834d;
  }
  
  .earning-table tbody tr:hover {
    background-color: #050a3f;
    color: #ffffff;
  }
  
  .earning-table td {
    color: #e0e0e0;
  }
  
  /* Tab Content */
  .tab-content h2 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  
  .tab-content p {
    background-color: #0b1f81;
    font-size: 13px;
    padding: 10px;
    border-radius: 5px;
    color: #d7d7d7;
    margin-bottom: 20px;
  }
  
  .tab-content label {
    display: block;
    margin-bottom: 10px;
    color: #fdfdfd;
  }
  
  .tab-content input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #c5c6c7;
    background-color: #0b0c10;
    color: #c5c6c7;
  }
  