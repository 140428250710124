.section {
    width: 980px; /* Adjusted from fixed width to accommodate content */
    padding: 10px;
    border: 2px solid #0b1f81;
    background: linear-gradient(135deg, #090f24, #031274);
    border-radius: 10px;
    box-shadow: 0 0 3px #0b1f81;
    display: flex;
    flex-direction: column;
    height: 550px; /* Ensure this can expand as needed */
    overflow-y: auto; 
    position: relative;
    z-index: 1;
    pointer-events: auto;
    margin-bottom: 20px;
}
