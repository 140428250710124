/* Home.css */
.home-dashboard {
    color: #fff;
    font-family: Arial, sans-serif;
    padding: 20px;
}

.right-and-left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.stats-grid {
    display: grid;
    justify-content: center;  
    align-items: center;      
    grid-template-columns: repeat(2, 2fr);  
    gap: 14px;             
    height: 180px;         
    width: 50%;            
}


.image {
    font-size: 18px;
    width: 600px;
    border: 2px solid #0b1f81;
    background: linear-gradient(135deg, #090f24, #031274);
    border-radius: 10px;
    box-shadow: 0 0 3px #0b1f81;
    height: 180px;
}

.stat {
    padding: 10px;
    border: 1px solid #0b8125;
    background: linear-gradient(135deg, #037410, #03741000);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
}

.stat-value {
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* You can change color based on your preference */
}

.stat-label {
    color: #c5c5c5;
    font-size: 13px;
}

.announcements {
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid #0b1f81;
    background: linear-gradient(135deg, #091335, #031274);
    border-radius: 10px;
    box-shadow: 0 0 3px #0b1f81;
    height: 300px;
    width: 280px;
}

.announcements li {
    font-size: 16px;
    padding: 5px;
    border-radius: 15px;
    background-color: rgba(5, 28, 175, 0.5);
    text-align: start;
    margin-bottom: 5px;
}

.announcements h2 {
    font-size: 20px;
}


.announcement-time {
    font-size: 14px;
    color: #888;
}

.announcement-message {
    display: block;
    margin-top: 5px;
}

.annoucements-texts {
    max-height: 100px;
}

canvas {
    display: block; /* Ensures the canvas is properly aligned */
    padding: 10px;
    border: 2px solid #0b1f81;
    background: linear-gradient(135deg, #090f24, #031274);
    border-radius: 10px;
    box-shadow: 0 0 3px #0b1f81;
    border-radius: 8px;
}

.view-all{
    margin: 0;
    margin-top: 15px;
}

.special {
    scale: 0.7;
    margin: 0;
    margin-top: -5px;
}

.boss {
    z-index: 1;
    width: 100vw;
    height: 100vh;
}