/* Dashboard.css */
.dashboard {
  display: flex;
  flex-direction: row; /* default direction */
  color: white;
  max-width: 100vw;
  padding: 40px;
}

.main {
  flex-grow: 1;
}

/* Media query for tablets and smaller devices */
@media (max-width: 768px) {
  .dashboard {
      flex-direction: column; /* Stack vertically on small screens */
  }

  /* Ensure the navbar takes appropriate space and possibly other styling as needed */
  .navbar {
      width: 100%; /* Full width */
      order: -1; /* Render before the main content */
  }

  .main {
      width: 100%; /* Optional: Adjust width as per design needs */
  }
}
