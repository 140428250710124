/* NFTCollection.css */

.nft-page {
    padding: 20px;
    color: #e0e0e0;
    font-family: 'Roboto', sans-serif;
  }
  
  .nft-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .search-bar {
    width: 400px;
    padding: 14px;
    border-radius: 15px;
    border: 1px solid #010055;
    background-color: #0b1f81;
    color: #e0e0e0;
    font-size: 18px;
  }
  
  .filter-button {
    padding: 14px 20px;
    border-radius: 15px;
    border: none;
    background-color: #0b1f81;
    color: #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 18px;

  }
  
  .filter-button:hover {
    background-color: #0b1963;
  }
  
  .nft-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .nft-card {
    background: linear-gradient(135deg, #090f24, #031274);
    border: 2px solid #0b1f81;
    box-shadow: 0 0 3px #0b1f81;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .nft-card:hover {
    transform: translateY(-10px);
  }
  
  .nft-image {
    width: 100%;
    height: 180px;
    width: 250px;
    object-fit: cover;
  }
  
  .nft-details {
    padding: 5px;
    text-align: center;
  }
  
  .nft-details h3 {
    margin: 0 0 0px;
    font-size: 18px;
    font-weight: 500;
  }
  
  .nft-details p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .nft-details span {
    color: #aaa;
  }
  