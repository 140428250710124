.steps-list {
    list-style: none;
    padding-left: 20px;
  }
  
  .step {
    margin-bottom: 15px;
    padding-right: 15px;
  }
  
  .sub-steps-list {
    list-style: none;
  }
  
  .sub-step {
    margin-bottom: 5px;
  }
  
  .inner-sub-steps-list {
    list-style: none;    
  }
  
  .inner-sub-step {
    margin-bottom: 5px;
    color: #666;
  }

  a {
    color: white; 
    text-decoration: none; 
    font-weight: bold; 
  }
  
  a:hover {
    color: #ffffff80; 
  }
  .Sepoliapage {
    min-width: 100%;
    text-align: right;
  }


.coinbase1 {
    min-width: 100%;

}
.coinbase2 {
    min-width: 100%;
    margin-left: -30px;
}

.Metamask2 {
    min-width: 100%;
    margin-top: -80px;

}

.Metamask1 {
    min-width: 100%;
    margin-top: -100px;

}

@media only screen and (max-width: 767px) {

    
    .Metamask2 {
        min-width: 100%;
        margin-top: -50px;
    
    }
    
    .Metamask1 {
        min-width: 100%;
        margin-top: -50px;
    
    }
    .coinbase2 {
        min-width: 100%;
        margin-left: -13px;
    }

    .tokenid {
        font-size: 20px;
        max-width: 90vw;
        word-break: break-all;
    }
}