.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px solid #0b1f81;
  background: linear-gradient(135deg, #090f24, #031274);
  border-radius: 10px;
  box-shadow: 0 0 3px #0b1f81;
  color: white;
}

.wallet-info {
  color: #FFD700; /* Gold color stands out well */
  background-color: #0a0f33; /* Dark background for contrast */
  font-size: 16px;
  padding: 10px 20px; /* Ample padding for better readability */
  margin-right: 20px;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow for a floating effect */
  border: 1px solid #FFD700; /* Subtle border to enhance visibility */
  display: inline-block; /* Keeps the background tight around the text */
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

.wallet-info:hover {
  background-color: #FFD700; /* Invert the color scheme on hover */
  color: #0a0f33; /* Dark text on light background */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Enhance shadow for more depth */
  cursor: pointer; /* Indicates the element is interactive */
}



.wallet-connected {
  color: #00FF00; /* Green color to indicate successful connection */
  font-size: 16px;
}
